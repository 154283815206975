// The dropdown wrapper (`<div>`)
.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative;
}

.dropdown-toggle {
  // Generate the caret automatically
  @include caret;
  z-index: 1020;
}

// The dropdown menu
.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: $zindex-dropdown;
  display: none; // none by default, but block on "open" of the menu
  float: left;
  width: 100%;
  min-width: $dropdown-min-width;
  padding: $dropdown-padding-y 0;
  margin: $dropdown-spacer 0 0; // override default ul
  font-size: $font-size-base; // Redeclare because nesting can cause inheritance issues
  color: $text-gray;
  text-align: left; // Ensures proper alignment if parent has it changed (e.g., modal footer)
  list-style: none;
  background-color: $dropdown-bg;
  background-clip: padding-box;
  border: $dropdown-border-width solid $dropdown-border-color;
  @include border-radius($dropdown-border-radius);
  @include box-shadow($dropdown-box-shadow);
}

.dropdown-menu-right {
  right: 0;
  left: auto;
}

// Allow for dropdowns to go bottom up (aka, dropup-menu)
// Just add .dropup after the standard .dropdown class and you're set.
.dropup {
  .dropdown-menu {
    top: auto;
    bottom: 100%;
    margin-top: 0;
    margin-bottom: $dropdown-spacer;
  }

  .dropdown-toggle {
    @include caret(up);
  }
}

.dropright {
  .dropdown-menu {
    top: 0;
    right: auto;
    left: 100%;
    margin-top: 0;
    margin-left: $dropdown-spacer;
  }

  .dropdown-toggle {
    @include caret(right);
    &::after {
      vertical-align: 0;
    }
  }
}

.dropleft {
  .dropdown-menu {
    top: 0;
    right: 100%;
    left: auto;
    margin-top: 0;
    margin-right: $dropdown-spacer;
  }

  .dropdown-toggle {
    @include caret(left);
    &::before {
      vertical-align: 0;
    }
  }
}

// When enabled Popper.js, reset basic dropdown position
// stylelint-disable no-duplicate-selectors
.dropdown-menu {
  &[x-placement^='top'],
  &[x-placement^='right'],
  &[x-placement^='bottom'],
  &[x-placement^='left'] {
    right: auto;
    bottom: auto;
  }
}
// stylelint-enable no-duplicate-selectors

// Dividers (basically an `<hr>`) within the dropdown
.dropdown-divider {
  @include nav-divider($dropdown-divider-bg);
}

// Links, buttons, and more within the dropdown menu
//
// `<button>`-specific styles are denoted with `// For <button>s`
.dropdown-item {
  display: block;
  width: 100%; // For `<button>`s
  padding: $dropdown-item-padding-y $dropdown-item-padding-x;
  clear: both;
  font-weight: $font-weight-normal;
  color: $dropdown-link-color;
  text-align: inherit; // For `<button>`s
  white-space: nowrap; // prevent links from randomly breaking onto new lines
  background-color: transparent; // For `<button>`s
  border: 0; // For `<button>`s
  border-top: 1px solid #3b4045;
  line-height: 1.2;
  font-weight: 400;

  @include hover-focus {
    color: $dropdown-link-hover-color;
    text-decoration: none;
    @include gradient-bg($dropdown-link-hover-bg);
  }

  &.active,
  &:active {
    color: $dropdown-link-active-color;
    text-decoration: none;
    @include gradient-bg($dropdown-link-active-bg);
  }

  &.disabled,
  &:disabled {
    color: $dropdown-link-disabled-color;
    background-color: transparent;
    // Remove CSS gradients if they're enabled
    @if $enable-gradients {
      background-image: none;
    }
  }
}

.dropdown-menu.show {
  display: block;
}

// Dropdown section headers
.dropdown-header {
  display: block;
  padding: 1rem;
  margin-bottom: 0; // for use with heading elements
  font-size: $font-size-sm;
  color: $dropdown-header-color;
  white-space: nowrap; // as with > li > a
  text-transform: uppercase;
  text-align: center;
}

// Dropdown text
.dropdown-item-text {
  display: block;
  padding: $dropdown-item-padding-y $dropdown-item-padding-x;
  color: $dropdown-link-color;
}

.card {
  .dropdown {
    margin: 1.35rem 0;
    color: $text-gray;
    width: 100%;
    .dropdown-menu {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      box-shadow: none !important;
      border: none !important;
    }
    .btn.btn-secondary {
      box-shadow: none !important;
      position: relative;
      width: 100%;
      text-align: left;
      color: $text-gray;
      font-weight: 400;
      border-bottom: none;
      padding: 1.2rem 2rem 1.2rem 1.5rem;
      &:active,
      &:focus {
        background: $brand-gray;
        border-color: $brand-gray;
      }
      &:after {
        position: absolute;
        content: '';
        top: 17px;
        right: 1.4rem;
        margin-top: 13px;
        width: 10px;
        height: 7px;
        display: block;
        background-color: transparent;
        background-repeat: no-repeat;
        background-position: center;
        background-image: url('../assets/arrow-down.svg');
        background-size: 11px 7px;
      }
    }
  }
  .show > .btn.btn-secondary {
    background: $brand-gray;
    border-color: $brand-gray;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border-bottom: 1px solid #3b4045;
    &:focus,
    &:active {
      box-shadown: none;
    }
  }
  .show {
    .dropdown-menu {
      bottom: auto;
      top: -3px !important;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      box-shadow: none !important;
      border: none !important;
    }
  }
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

main {
  width: 100%;
  .container-fluid {
    max-width: 1200px;
  }
}

h1,
h2 {
  text-transform: uppercase;
  letter-spacing: 3.6px;
}

footer {
  border-top: 2px solid $brand-gray;
  display: block;
  text-align: center;
  line-height: 1.5;
  width: 100%;
  color: $text-gray;
  font-size: 16px;
  padding: 0 0 40px 0;
  margin: 60px 0 0 0;
  @include media-breakpoint-up(lg) {
    text-align: left;
    display: flex;
    align-items: center;
    font-size: 14px;
    justify-content: space-between;
    height: 135px;
    padding: 0px 20px 0 30px;
    margin: 110px 0 0 0;
  }
  @include media-breakpoint-up(xl) {
    padding: 0 50px 0 60px;
    font-size: 16px;
  }
  svg {
    vertical-align: middle;
  }
  .copyright {
    padding: 0 10px;
    margin: 0;
    @include media-breakpoint-up(lg) {
      padding: 0;
      line-height: 22px;
    }
  }
  a.footer-logo {
    display: block;
    margin: 0 auto;
    height: 60px;
    box-sizing: content-box;
    padding: 42px 0;
    border-bottom: 2px solid $brand-gray;
    svg {
      max-width: 100%;
      height: 60px;
      width: 65px;
    }
  }
  ul.menu-links {
    margin: 0;
    padding: 0;
    @include media-breakpoint-up(lg) {
      display: flex;
      text-align: center;
    }
    li {
      line-height: 1.5;
      border-bottom: 2px solid $brand-gray;
      padding: 34px 10px;
      @include media-breakpoint-up(lg) {
        line-height: 22px;
        margin: 0;
        padding: 0 10px;
        border: none;
        @include media-breakpoint-up(xl) {
          padding: 0 20px;
        }
      }
      a {
        letter-spacing: 0;
        &,
        &:link,
        &:visited {
          color: $text-gray;
        }
        &:hover,
        &:focus {
          color: #fff;
        }
        &.text-primary {
          color: $primary;
        }
      }
    }
  }

  ul.menu-social {
    margin: 0;
    padding: 0;
    @include media-breakpoint-up(lg) {
      display: flex;
    }
    li {
      margin: 0;
      padding: 0;
      display: inline-block;
      a {
        display: block;
        height: 40px;
        box-sizing: content-box;
        padding: 40px 25px 35px 25px;
        @include media-breakpoint-up(lg) {
          height: 22px;
          padding: 0 10px;
        }
      }
    }
    svg {
      width: 40px;
      height: 40px;
      line-height: 1;
      @include media-breakpoint-up(lg) {
        width: 22px;
        height: 22px;
        vertical-align: top;
      }
    }
  }
}

.for-partners footer {
  margin: 0;
}

header {
  display: flex;
  align-items: center;
  position: relative;
  z-index: 100;
  justify-content: space-between;
  margin: 0;
  width: 100%;
  border: none;
  background: transparent;
  font-size: 15px;
  padding: 53px 10px;
  @include media-breakpoint-up(md) {
    padding: 0 20px;
  }
  @include media-breakpoint-up(lg) {
    font-size: 16px;
  }
  @include media-breakpoint-up(xl) {
    padding: 0 60px;
  }
  a.brand-logo {
    display: block;
    text-align: center;
    margin: 0 auto;
    svg {
      height: 36px;
      width: 242px;
      vertical-align: middle;
    }
  }
  a:hover {
    text-decoration: none;
    cursor: pointer;
  }
  &.header-none {
    margin: 0;
    height: 0;
    display: none;
    visibility: hidden;
    padding: 0;
  }
  &.header-normal,
  &.header-dashboard,
  &.header-filled,
  &.header-card {
    @include media-breakpoint-up(md) {
      height: 136px;
    }
    .brand-logo {
      text-align: center;
      margin: 0 auto;
      svg {
        height: 36px;
        width: 242px;
        vertical-align: middle;
      }
      @include media-breakpoint-up(md) {
        text-align: left;
        margin: 0;
      }
    }
  }
  &.header-logo {
    background: $brand-gray;
    @include media-breakpoint-up(md) {
      padding: 40px 10px;
      background: $brand-gray;
      height: 116px;
    }
    a.brand-logo {
      text-align: center;
    }
  }

  &.header-dashboard {
    @include media-breakpoint-up(md) {
      background: $brand-gray;
      position: relative;
      z-index: 1;
      margin: 0 0 100px 0;

      &:after {
        background: $brand-gray;
        bottom: 0;
        content: '';
        display: block;
        height: 50%;
        left: 0;
        position: absolute;
        right: 0;
        transform: skewY(-1.5deg);
        transform-origin: 100%;
        z-index: -1;
        box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);
      }
    }
  }

  &.header-filled {
    background: $brand-gray;
  }

  .menu a:not(.btn) {
    padding: 10px 20px;
    line-height: 1.5;
    @include media-breakpoint-up(lg) {
      padding: 10px 12px;
    }
    @include media-breakpoint-up(xl) {
      padding: 10px 23px;
    }
    &,
    &:link,
    &:visited {
      color: $primary;
    }
    &:hover,
    &:focus {
      color: #fff;
    }
    &:last-child {
      padding-right: 0;
    }
  }

  .account {
    position: relative;
    margin-right: 20px;
    @include media-breakpoint-up(lg) {
      margin-right: 40px;
    }
    @include media-breakpoint-up(xl) {
      margin-right: 65px;
    }
    &:after {
      @include media-breakpoint-up(lg) {
        border-right: 1px solid lighten($brand-gray, 15%);
        content: '';
        display: block;
        position: absolute;
        height: 16px;
        width: 1px;
        top: 14px;
        right: -20px;
      }
      @include media-breakpoint-up(xl) {
        right: -35px;
      }
    }
  }

  .menu.scroll a:not(.btn) {
    padding: 10px 9px;
    font-size: 14px;
    cursor: pointer;
    @include media-breakpoint-up(lg) {
      padding: 10px 12px;
      font-size: 16px;
    }
    @include media-breakpoint-up(xl) {
      padding: 10px 30px;
    }
    &,
    &:link,
    &:visited {
      color: #fff;
    }
    &:hover,
    &:focus {
      color: $primary;
    }
  }
}

//burger menu css
.bm-menu-wrap {
  top: 0;
  z-index: 2000;
}

.bm-burger-button {
  position: absolute;
  width: 26px;
  height: 20px;
  right: 25px;
  top: 26px;
}

.bm-burger-button .bm-burger-bars {
  background: $text-gray;
  height: 15% !important;
  border-radius: 0;
}

.bm-cross-button {
  height: 24px;
  width: 24px;
  top: 17px;
}

.bm-menu {
  background: $brand-gray;
  top: 0;
  margin: 0;
  padding: 0;
  font-size: 16px;
}

.bm-item-list {
  color: $primary;
  padding: 0;
}

.bm-item {
  display: block;
  padding: 20px;
  color: $primary;
  border-bottom: 1px solid lighten($brand-gray, 15%);
}

.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
  top: 0;
  left: 0;
}

.bm-cross {
  background: #000;
}

//login, reset, forgot-password
main.main-login {
  width: 100%;
  @include media-breakpoint-up(md) {
    display: flex;
    align-items: center;
  }
  .form-wrapper {
    margin: 0 auto;
    width: 100%;
    padding: 30px 30px 60px 30px;
    @include media-breakpoint-up(sm) {
      width: 100%;
      max-width: 380px;
      padding: 30px 0;
    }
  }
  form {
    margin: 0;
    h1 {
      text-transform: uppercase;
      font-size: 26px;
      margin: 0 auto 30px auto;
      text-align: center;
    }
  }
  .alert {
    margin: 0 auto 30px auto;
  }
}

main.main-form {
  padding-bottom: 30px;
  form {
    width: 100%;
    max-width: 620px;
    margin: 30px auto 0 auto;
    padding: 0 20px;
    clear: both;
    + form {
      margin-top: 60px;
    }
    h2 {
      text-align: center;
      margin: 0 0 20px 0;
    }
    p {
      line-height: 1.89;
    }
  }
}

.bottom-links {
  padding: 30px 0 0 0;
  font-size: 16px;
  display: block;
  width: 100%;
  margin: 0 0 30px 0;
  a {
    &,
    &:link,
    &:visited {
      color: $text-gray;
    }
    &:hover,
    &:focus {
      color: $primary;
    }
  }
}

.policy {
  @include media-breakpoint-up(sm) {
    &.container-fluid {
      max-width: 980px !important;
      margin: 60px auto;
    }
  }
  h1 {
    margin: 0 0 30px 0;
  }
  h2 {
    margin: 0 0 20px 0;
  }
  h1,
  h2 {
    text-transform: none;
    letter-spacing: normal;
  }
  h3,
  h4,
  h5,
  h6 {
    font-weight: 700 !important;
  }
  p {
    line-height: 1.5;
    margin: 0 0 30px 0;
    font-weight: 400;
  }
  p + h2,
  ul + h2 {
    margin-top: 60px;
  }
  ul,
  dl,
  ol {
    margin: 0 0 30px 10px;
    padding: 0;
    font-weight: 400;
    li {
      line-height: 1.5;
      margin: 0 0 10px 10px;
      padding: 0;
    }
  }
}

.error-block {
  height: 100vh;
  display: flex;
  align-items: center;
  font-size: 18px;
  h1 {
    font-size: 35px;
    margin: 0 0 20px 0;
  }
  .btn {
    max-width: 300px;
    margin: 0 auto;
  }
  p {
    margin: 0 0 35px 0;
  }
}

.home {
  footer {
    margin: 0;
    z-index: 100;
    position: relative;
    background: $body-bg;
  }
  @include media-breakpoint-up(md) {
    .top {
      margin-top: 80px;
    }
  }
}
.rx footer {
  margin-top: 0 !important;
}

.faq .Collapsible {
  margin: 0;
  width: 100%;
  border-bottom: solid 2px #24292d;
  padding: 10px 0;
}
.faq .Collapsible:last-child {
  border: none;
}
.faq .Collapsible__trigger {
  color: #8c99a7;
  font-size: 18px;
  margin: 0;
  padding: 22px 35px 22px 30px;
  display: block;
  font-weight: 600;
  line-height: 1.5;
  cursor: pointer;
  position: relative;
  width: 100%;
  transition: all 0.2s ease;
}

@media (min-width: 768px) {
  .faq .Collapsible__trigger {
    padding: 34px 50px 34px 50px;
  }
}

.faq .Collapsible__trigger.is-closed:after,
.faq .Collapsible__trigger.is-open:after {
  display: block;
  content: '';
  width: 10px;
  height: 10px;
  cursor: pointer;
  margin-top: -5px;
  position: absolute;
  top: 50%;
  right: 30px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 10px 10px;
}

.faq .Collapsible__trigger.is-open {
  color: #ffa500;
}

.faq .Collapsible__trigger.is-open:after {
  background-image: url(./assets/arrowopen.svg);
}

.faq .Collapsible__trigger.is-closed:after {
  background-image: url(./assets/arrowright.svg);
}

.faq .Collapsible__contentInner {
  padding: 0 30px 20px 30px;
  color: #fff;
}

@media (min-width: 768px) {
  .faq .Collapsible__contentInner {
    padding: 0 50px 30px 50px;
  }
}

.Toastify__close-button svg, .Toastify__toast {
    font-size: 15px;
}

.Toastify__toast--success {
    background: rgba(7, 188, 12, 0.7) !important;
    box-shadow: 0 8px 20px 0 rgba(7, 188, 12, 0.2) !important;
}
.Toastify__toast--error {
    background: rgba(231, 76, 60, 0.7) !important;
    box-shadow: 0 8px 20px 0 rgba(231, 76, 60, 0.2) !important;
}
.custom-toast-style {
    backdrop-filter: blur(8px) !important;
    -webkit-backdrop-filter: blur(8px) !important;
    border: 1px solid rgba(255, 255, 255, 0.18) !important;
}

/*!
 * Bootstrap v4.1.1 (https://getbootstrap.com/)
 * Copyright 2011-2018 The Bootstrap Authors
 * Copyright 2011-2018 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */

@import '~bootstrap/scss/functions';
@import 'bootstrap-custom/variables';
@import '~bootstrap/scss/mixins';
@import '~bootstrap/scss/root';
@import '~bootstrap/scss/reboot';
@import '~bootstrap/scss/type';
@import '~bootstrap/scss/images';
//@import "~bootstrap/scss/code";
@import '~bootstrap/scss/grid';
//@import "~bootstrap/scss/tables";
@import 'bootstrap-custom/forms';
@import 'bootstrap-custom/buttons';
@import '~bootstrap/scss/transitions';
@import 'bootstrap-custom/dropdown';
//@import "~bootstrap/scss/button-group";
//@import "~bootstrap/scss/input-group";
//@import "~bootstrap/scss/custom-forms";
//@import "~bootstrap/scss/nav";
//@import "~bootstrap/scss/navbar";
@import 'bootstrap-custom/card';
//@import "~bootstrap/scss/breadcrumb";
//@import "~bootstrap/scss/pagination";
//@import "~bootstrap/scss/badge";
//@import "~bootstrap/scss/jumbotron";
@import 'bootstrap-custom/alert';
//@import "~bootstrap/scss/progress";
//@import "~bootstrap/scss/media";
//@import "~bootstrap/scss/list-group";
//@import "~bootstrap/scss/close";
@import 'bootstrap-custom/modal';
//@import "~bootstrap/scss/tooltip";
//@import "~bootstrap/scss/popover";
@import 'bootstrap-custom/carousel';


@import "~bootstrap/scss/utilities/align";
@import "~bootstrap/scss/utilities/background";
@import "~bootstrap/scss/utilities/borders";
@import "~bootstrap/scss/utilities/clearfix";
@import "~bootstrap/scss/utilities/display";
@import "~bootstrap/scss/utilities/embed";
//@import "~bootstrap/scss/utilities/flex";
@import "~bootstrap/scss/utilities/float";
@import "~bootstrap/scss/utilities/position";
@import "~bootstrap/scss/utilities/screenreaders";
@import "~bootstrap/scss/utilities/shadows";
@import "~bootstrap/scss/utilities/sizing";
//@import "~bootstrap/scss/utilities/spacing";
//@import "~bootstrap/scss/utilities/text";
@import "~bootstrap/scss/utilities/visibility";

@import '~bootstrap/scss/print';


@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
    .h1 {
        @apply text-4xl font-extrabold tracking-tighter;
    }

    .h2 {
        @apply text-3xl font-extrabold tracking-tighter;
    }

    .h3 {
        @apply text-3xl font-extrabold;
    }

    .h4 {
        @apply text-2xl font-extrabold tracking-tight;
    }

    @screen md {
        .h1 {
            @apply text-5xl;
        }

        .h2 {
            @apply text-4xl;
        }
    }

    .btn,
    .btn-sm,
    .btn-xs {
        @apply font-medium text-sm inline-flex items-center justify-center border border-transparent rounded leading-5 shadow-sm transition duration-150 ease-in-out;
    }

    .btn {
        @apply px-2.5 py-2;
    }

    .btn-sm {
        @apply px-2 py-1;
    }

    .btn-xs {
        @apply px-2 py-0.5;
    }


    h2{
        @apply text-3xl  !important;
    }
    .btn-primary {

        @apply btn bg-primary-500 hover:bg-primary-600 text-black hover:text-white px-7 py-3 text-xl ;
    }

    input[type="search"]::-webkit-search-decoration,
    input[type="search"]::-webkit-search-cancel-button,
    input[type="search"]::-webkit-search-results-button,
    input[type="search"]::-webkit-search-results-decoration {
        -webkit-appearance: none;
    }

    .form-input,
    .form-textarea,
    .form-multiselect,
    .form-select,
    .form-checkbox,
    .form-radio {
        @apply text-sm text-gray-800 bg-white border;
    }

    .form-input,
    .form-textarea,
    .form-multiselect,
    .form-select,
    .form-checkbox {
        @apply rounded;
    }

    .form-input,
    .form-textarea,
    .form-multiselect,
    .form-select {
        @apply leading-5 py-2 px-3 border-gray-200 hover:border-gray-300 focus:border-indigo-300 shadow-sm;
    }

    .form-input,
    .form-textarea {
        @apply placeholder-gray-400;
    }

    .form-select {
        @apply pr-10;
    }

    .form-checkbox,
    .form-radio {
        @apply text-indigo-500 border border-gray-300;
    }

    .clever-btn {
        @apply btn rounded-lg text-custom-text;
        background-image: linear-gradient(to top, var(--custom-accent), var(--custom-primary));
    }
    .clever-btn-white {
        @apply btn rounded-lg border-white text-custom-text hover:text-cleverGray-200 hover:border-cleverGray-200;
    }
    .clever-gradient {
        @apply bg-gradient-to-b from-custom-primary to-custom-accent;
    }
    .big-clever-btn {
        @apply clever-btn px-16 rounded-3xl py-2.5 text-lg font-semibold ;
    }
    .clh-input {
        @apply form-input block w-full mt-0.5 bg-cleverGray-300 border-cleverGray-400 text-custom-text placeholder-cleverGray-100 hover:border-cleverGray-250 focus:border-custom-primary;
    }
    .clh-select {
        @apply form-select block w-full mt-0.5 bg-cleverGray-300 border-cleverGray-400 text-custom-text placeholder-cleverGray-100 hover:border-cleverGray-250 focus:border-custom-primary text-sm;
    }

}

.clever-btn:hover {
    filter: brightness(90%);
}
.clever-btn-disabled {
    filter: brightness(80%);
}

.gray-gradient {
    background-image: linear-gradient(to bottom, #575757 0%, #353535 100%);
}

.fade-in-400 {
    animation: fadeIn 400ms forwards;
}
.fade-in-300 {
    animation: fadeIn 300ms forwards;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.styled-scrollbar::-webkit-scrollbar {
    width: 12px;
    height: 12px;
}
.styled-scrollbar::-webkit-scrollbar-track {
    background-color: transparent;
}

.styled-scrollbar::-webkit-scrollbar-thumb {
    background-color: rgba(0,0,0,0.12);
    border-radius: 12px;
}

.styled-scrollbar-big::-webkit-scrollbar {
    width: 16px;
    height: 16px;
}
.styled-scrollbar-big::-webkit-scrollbar-track {
    background-color: transparent;
}

.styled-scrollbar-big::-webkit-scrollbar-thumb {
    background-color: rgba(0,0,0,0.3);
    border-radius: 16px;
}

body {
    font-family: var(--custom-font), 'Inter', sans-serif !important;
    background-color: var(--custom-body);
}

.VoucherModal__printContainer {
    display: none;
}
@media print {
    .VoucherModal__printContainer {
        display: block;
    }
}

.RxLookup__pharmacyImg {
    max-width: 150px;
}
@media (max-width: 500px) {
    .RxLookup__pharmacyImg {
        max-width: 100px;
    }
}
.RxLookup__pharmacyImg svg {
    width: 100% !important;
    height: 100% !important;
    color: var(--custom-text) !important;
    fill: var(--custom-text) !important;
}

.VoucherPrintCard {
    width: 100%;
    height: 100%;
    background: transparent;
    padding: 20px;
    font-family: var(--custom-font), sans-serif;
}
.VoucherPrintCard__top,
.VoucherPrintCard__bottom {
    color: #2a2d30;
    width: 540px;
    height: 324px;
    margin: 40px auto;
    display: block;
    padding: 30px 50px;
    letter-spacing: 0.2px;
    line-height: normal;
    position: relative;
}
.VoucherPrintCard__top div,
.VoucherPrintCard__bottom div {
    display: block;
    width: 100%;
}
.VoucherPrintCard__logo {
    max-width: 100%;
    width: 258px;
    height: 43px;
    display: block;
    margin: 0;
    padding: 0;
}
.VoucherPrintCard__bgImg {
    width: 100%;
    height: 100%;
    position: absolute;
    inset: 0;
}
.VoucherPrintCard__content {
    position: relative;
    z-index: 10;
}
.VoucherPrintCard__bottom {
    color: white;
    text-align: right;
}
.VoucherPrintCard__text-primary {
    font-size: 18px;
    letter-spacing: 0.4px;
    color: var(--custom-primary);
    font-weight: 400;
    margin-top: 6px;
    margin-bottom: 12px;
}
.VoucherPrintCard__text-norm {
    font-size: 16px;
    font-weight: 300;
    color: #2a2d30;
    display: block;
    width: 100%;
}
.VoucherPrintCard__not-ins {
    color: #8f8c8f;
    font-size: 8px;
    font-weight: 600;
    margin: 18px 0 22px 0;
}
.VoucherPrintCard__accepted {
    color: #2a2d30;
    font-size: 12px;
    font-weight: 700;
}
.VoucherPrintCard__discount {
    font-size: 14px;
    font-weight: 700;
    margin-bottom: 16px;
    color: white;
    margin-top: 75px;
}
.VoucherPrintCard__phone {
    font-size: 14px;
    font-weight: 400;
}
.VoucherPrintCard__info {
    font-size: 14px;
    font-weight: 700;
    margin-top: 16px;
    color: var(--custom-primary);
}



.DotsSpinner,
.DotsSpinner:before,
.DotsSpinner:after {
    border-radius: 50%;
    width: 2.5em;
    height: 2.5em;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation: load7 1.8s infinite ease-in-out;
    animation: load7 1.8s infinite ease-in-out;
}
.DotsSpinner {
    color: var(--custom-text);
    margin: 0;
    position: relative;
    top: -2.5em;
    text-indent: -9999em;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-animation-delay: -0.16s;
    animation-delay: -0.16s;
}
.DotsSpinner:before,
.DotsSpinner:after {
    content: '';
    position: absolute;
    top: 0;
}
.DotsSpinner:before {
    left: -3.5em;
    -webkit-animation-delay: -0.32s;
    animation-delay: -0.32s;
}
.DotsSpinner:after {
    left: 3.5em;
}
@-webkit-keyframes load7 {
    0%,
    80%,
    100% {
        box-shadow: 0 2.5em 0 -1.3em;
    }
    40% {
        box-shadow: 0 2.5em 0 0;
    }
}
@keyframes load7 {
    0%,
    80%,
    100% {
        box-shadow: 0 2.5em 0 -1.3em;
    }
    40% {
        box-shadow: 0 2.5em 0 0;
    }
}

*:focus,
button:focus,
.btn:focus,
.btn-sm:focus,
.form-input:focus,
.form-textarea:focus,
.form-multiselect:focus,
.form-select:focus,
.form-checkbox:focus,
.form-radio:focus {
    outline: none;
}

.form-input:focus,
.form-textarea:focus,
.form-multiselect:focus,
.form-select:focus,
.form-checkbox:focus,
.form-radio:focus {
     box-shadow: none !important;
}

.custom-toast {
    background-color: var(--custom-foreground) !important;
    color: var(--custom-text) !important;
}


:root {
    --custom-body: #1e2126;
    --custom-modalBackground: #3F3F3F; /* clever gray 400 */
    --custom-primary: #d98c00; /* clhPrimary */
    --custom-foreground: #24292d; /* clever gray 350 */
    --custom-background: #1e2126; /* clever gray 600 */
    --custom-accent: #d98c00; /* clhPrimary 500 */
    --custom-text: #ffffff;
    --custom-border: #1e2126;
    --custom-font: 'Nunito';
    --custom-dropdown-text: white;
    --custom-dropdown: #4C4C4C;
    --custom-dropdown-highlight: #333435;
    --custom-icon: #3F3F3F;
}

.unstyled-dots{
    list-style-type: none;
}
li{
    list-style-type: disc;
}

input[type='text'],input[type='email'],input[type='tel']{
    background-color: var(--custom-foreground);
}

.glow-shadow {
    box-shadow: 0 0 0.5px #fff, 0 0 2.5px #fff, 0 0 5px #d98c00, 0 0 7.5px #d98c00, 0 0 10px #d98c00, 0 0 12.5px #d98c00;
}
.glow-shadow-green {
    box-shadow: 0 0 0.5px #fff, 0 0 2.5px #fff, 0 0 5px #86efac, 0 0 7.5px #86efac, 0 0 10px #86efac, 0 0 12.5px #86efac;
}

.fade-in-section {
    opacity: 0;
    transform: translateY(20px);
    transition: opacity 1s ease-out, transform 0.6s ease-out;
    will-change: opacity, transform;
}
.fade-in-section.fade-is-visible {
    opacity: 1;
    transform: none;
}

.glp1-hero-outer-container {
    padding-left: 40px;
    padding-right: 40px;
    max-width: 900px;
    margin: 0 auto;
}

.glp1-hero-container {
    display: flex;
    flex-direction: row;
    width: 100%;
}

.glp1-hero-left {
    margin-right: 20px;
    width: 30%;
}

.glp1-hero-img {
    width: 70%;
    height: auto;
    object-fit: contain;
}

@media (max-width: 900px) {
    .glp1-hero-container {
        flex-direction: column-reverse;
    }
    .glp1-hero-outer-container {
        max-width: 550px;
    }
    .glp1-hero-img {
        width: 100%;
    }
    .glp1-hero-left {
        margin-top: 20px;
        margin-right: 0;
        width: 100%;
    }
}

@media (max-width: 768px) {
    .glp1-hero-outer-container {
        margin-bottom: 70px;
    }
}

//
// Base styles
//

.card {
  position: relative;
  z-index: 100;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: $card-bg;
  background-clip: border-box;
  border: none;
  margin-bottom: 2rem;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.3);
  @include border-radius($card-border-radius);

  > hr {
    margin-right: 0;
    margin-left: 0;
  }

  > .list-group:first-child {
    .list-group-item:first-child {
      @include border-top-radius($card-border-radius);
    }
  }

  > .list-group:last-child {
    .list-group-item:last-child {
      @include border-bottom-radius($card-border-radius);
    }
  }
}

.card-body {
  // Enable `flex-grow: 1` for decks and groups so that card blocks take up
  // as much space as possible, ensuring footers are aligned to the bottom.
  flex: 1 1 auto;
  padding: $card-spacer-x;
  hr {
    background: #24292d;
    height: 2px;
    border: none;
    margin: 1.35rem 0;
  }
}

.card-title {
  margin-bottom: $card-spacer-y;
}

.card-subtitle {
  margin-top: -($card-spacer-y / 2);
  margin-bottom: 0;
}

.card-text {
  text-align: center;
  line-height: 1.89;
  font-size: 18px;
  color: $text-gray;
  max-width: 85%;
  margin: 0 auto;
  + hr {
    margin: 2rem 0;
  }
}

.price {
  width: 100%;
  display: block;
  color: $primary;
  text-align: center;
  letter-spacing: 2.5px;
  font-weight: 600;
  min-height: 35px;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link {
  @include hover {
    text-decoration: none;
  }

  + .card-link {
    margin-left: $card-spacer-x;
  }
}

//
// Optional textual caps
//

.card-header {
  padding: $card-spacer-y $card-spacer-x;
  margin-bottom: 0.4rem; // Removes the default margin-bottom of <hN>
  background-color: $card-cap-bg;
  border-bottom: $card-border-width solid $card-border-color;
  text-align: center;
  text-transform: uppercase;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 2.5px;
  border-top: 6px solid $primary;

  &:first-child {
    @include border-radius($card-inner-border-radius $card-inner-border-radius 0 0);
  }

  + .list-group {
    .list-group-item:first-child {
      border-top: 0;
    }
  }
  span.sub {
    font-size: 12px;
    font-weight: 600;
    color: $text-gray;
    letter-spacing: 1.9px;
    text-transform: uppercase;
    line-height: 25px;
  }
  .text-right {
    font-size: 12px;
    letter-spacing: 1.9px;
    color: #ffa500;
    line-height: 22px;
  }
}

.card-footer {
  padding: $card-spacer-y $card-spacer-x;
  background-color: $card-cap-bg;
  border-top: $card-border-width solid $card-border-color;

  &:last-child {
    @include border-radius(0 0 $card-inner-border-radius $card-inner-border-radius);
  }
  .btn {
    width: 100%;
  }
}

//
// Header navs
//

.card-header-tabs {
  margin-right: -($card-spacer-x / 2);
  margin-bottom: -$card-spacer-y;
  margin-left: -($card-spacer-x / 2);
  border-bottom: 0;
}

.card-header-pills {
  margin-right: -($card-spacer-x / 2);
  margin-left: -($card-spacer-x / 2);
}

// Index image
.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: $card-img-overlay-padding;
}

.card-img {
  width: 100%; // Required because we use flexbox and this inherently applies align-self: stretch
  @include border-radius($card-inner-border-radius);
}

// Index image caps
.card-img-top {
  width: 100%; // Required because we use flexbox and this inherently applies align-self: stretch
  @include border-top-radius($card-inner-border-radius);
}

.card-img-bottom {
  width: 100%; // Required because we use flexbox and this inherently applies align-self: stretch
  @include border-bottom-radius($card-inner-border-radius);
}

// Index deck

.card-deck {
  display: flex;
  flex-direction: column;

  .card {
    margin-bottom: $card-deck-margin;
  }

  @include media-breakpoint-up(sm) {
    flex-flow: row wrap;
    margin-right: -$card-deck-margin;
    margin-left: -$card-deck-margin;

    .card {
      display: flex;
      // Flexbugs #4: https://github.com/philipwalton/flexbugs#flexbug-4
      flex: 1 0 0%;
      flex-direction: column;
      margin-right: $card-deck-margin;
      margin-bottom: 0; // Override the default
      margin-left: $card-deck-margin;
    }
  }
}

//
// Index groups
//

.card-group {
  display: flex;
  flex-direction: column;

  // The child selector allows nested `.card` within `.card-group`
  // to display properly.
  > .card {
    margin-bottom: $card-group-margin;
  }

  @include media-breakpoint-up(sm) {
    flex-flow: row wrap;
    // The child selector allows nested `.card` within `.card-group`
    // to display properly.
    > .card {
      // Flexbugs #4: https://github.com/philipwalton/flexbugs#flexbug-4
      flex: 1 0 0%;
      margin-bottom: 0;

      + .card {
        margin-left: 0;
        border-left: 0;
      }

      // Handle rounded corners
      @if $enable-rounded {
        &:first-child {
          @include border-right-radius(0);

          .card-img-top,
          .card-header {
            border-top-right-radius: 0;
          }
          .card-img-bottom,
          .card-footer {
            border-bottom-right-radius: 0;
          }
        }

        &:last-child {
          @include border-left-radius(0);

          .card-img-top,
          .card-header {
            border-top-left-radius: 0;
          }
          .card-img-bottom,
          .card-footer {
            border-bottom-left-radius: 0;
          }
        }

        &:only-child {
          @include border-radius($card-border-radius);

          .card-img-top,
          .card-header {
            @include border-top-radius($card-border-radius);
          }
          .card-img-bottom,
          .card-footer {
            @include border-bottom-radius($card-border-radius);
          }
        }

        &:not(:first-child):not(:last-child):not(:only-child) {
          @include border-radius(0);

          .card-img-top,
          .card-img-bottom,
          .card-header,
          .card-footer {
            @include border-radius(0);
          }
        }
      }
    }
  }
}

//
// Columns
//

.card-columns {
  .card {
    margin-bottom: $card-columns-margin;
  }

  @include media-breakpoint-up(sm) {
    column-count: $card-columns-count;
    column-gap: $card-columns-gap;
    orphans: 1;
    widows: 1;

    .card {
      display: inline-block; // Don't let them vertically span multiple columns
      width: 100%; // Don't let their width change
    }
  }
}

//
// Accordion
//

.accordion {
  .card:not(:first-of-type):not(:last-of-type) {
    border-bottom: 0;
    border-radius: 0;
  }

  .card:not(:first-of-type) {
    .card-header:first-child {
      border-radius: 0;
    }
  }

  .card:first-of-type {
    border-bottom: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }

  .card:last-of-type {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
}

.card.none {
  margin-bottom: 2.4rem;

  .card-header {
    border-bottom: none;
    width: 100%;
    line-height: 1;
    padding: 28px 30px;
    margin: 0;
    .col {
      vertical-align: middle;
    }
  }
}
